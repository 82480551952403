import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  components: {
    // Dropdown1
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear"
    ])
  },

  data() {
    return {
      // :core
      dataUser: {},
      searchMain: {},
      // :end core

      // :main
      tContents: [],
      tContentsLoading: false,
      tContentsDisabled: false,
      // :end main

      // :additional
      title: "Ubah Password anda",
      formValid: false,
      formValid1: false,
      form: {},

      isCurrentPass: String,
      isNewPass: String,
      isConfirmPass: String,
      // :end additional
      
      // :validation
      commonValidation: [v => !!v || 'This field is required']
      // :end validation
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: "/" },
      { title: "Change Profile" }
    ]),

    this.dataUser = this.currentUser;
    this.searchMain.tahun = this.currentYear;
    this.searchMain.admin_id = Number(this.currentUser.id);
    this.searchMain.masjid_id = Number(this.currentUser.masjid_id);
    
    this.loadDataUser(this.dataUser.jamaah_id);
  },

  watch: {
    // CODE
  },

  methods: {
    loadDataUser(id){
      var query = {
        main: this.searchMain,
        data: {id}
      }
      this.tContentsLoading = true;
      ApiService.post("jamaah/detail", query, 1).then(({data}) => {
        this.tContentsLoading = false;
        this.setForm(data.detail);
        // console.l og(data);
      }).catch(({response}) => {
        this.tContentsLoading = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
    async setForm(content){
      console.log(content);
      
      this.form = {...this.vFormDataPribadi};
      this.form.id = content[0].id;
      this.form.name = content[0].name;
      this.form.no_ktp = content[0].no_ktp;
      this.form.no_hp = content[0].no_hp;
      this.form.user_id = content[0].user_id;
      this.form.address = content[0].address;
      this.form.birth_place = content[0].birth_place;
      this.form.birth_date = content[0].birth_date;
      this.form.block_no = content[0].block_no;
      
    },
    submitFormPribadi(){
      if(!this.$refs.vFormDataPribadi.validate()) return;
    
      this.$swal({
        title: 'Peringatan',
        html: 'Apakah anda yakin ingin ubah data pribadi anda ini</strong> ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Submit"
      }).then((result) => {
        if(result.isConfirmed){
          var query = {
            main: this.searchMain,
            data: {...this.form}
          }

          this.tContentsLoading = true;
          ApiService.post("jamaah/update", query, 1).then(({data}) => {
            this.tContentsLoading = false;
            this.dialog = false;
            this.$swal("Success", data.message, "success");
          }).catch(({response}) => {
            this.tContentsLoading = false;
            this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
          })
        }
      })
    
    },
    submitForm(){
      if(!this.$refs.vFormPassword.validate()) return;
    
      this.$swal({
        title: 'Peringatan',
        html: 'Apakah anda yakin ingin ubah password user ini</strong> ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Submit"
      }).then((result) => {
        if(result.isConfirmed){

          var data  = {...this.form};
          data.id   = this.dataUser.id;

          var query = {
            main: this.searchMain,
            data: data
          }

          this.tContentsLoading = true;
          ApiService.post("user/change-password", query, 1).then(() => {
            this.tContentsLoading = false;
            this.$swal("Berhasil", "Password berhasil di ubah", "success");
            // this.form = {};
            this.$refs.vFormPassword.reset();
          }).catch(({ response }) => {
            this.tContentsLoading = false;
            this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
          })
        }
      })
    },

  },
  
};